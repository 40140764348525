import { Swiper, Autoplay, EffectFade, EffectCoverflow } from "swiper";
import "swiper/css";

type Direction = "horizontal" | "vertical";
type Effect =
  | "slide"
  | "fade"
  | "cube"
  | "coverflow"
  | "flip"
  | "creative"
  | "cards";

interface SlideSettings {
  slidesPerView?: number;
  spaceBetween?: number;
}
interface Breakpoints {
  [key: number | string]: SlideSettings;
}

Swiper.use([Autoplay, EffectFade, EffectCoverflow]);

export default () => ({
  autoPlayDuration: 5000,
  defaultSpeed: 300,
  start(settings: {
    autoplay: boolean;
    duration: number;
    ref: any;
    direction: Direction;
    speed: number;
    effect: Effect;
    slidesPerView: number;
    spaceBetween: number;
    breakpoints: Breakpoints;
    noSwiping: boolean;
  }): void {
    const swiper = new Swiper(settings.ref, {
      effect: settings.effect ? settings.effect : "slide",
      fadeEffect: { crossFade: true },
      speed: settings.speed ? settings.speed : this.defaultSpeed,
      loop: true,
      noSwiping: settings.noSwiping ? settings.noSwiping : true,
      // freeMode: true,
      direction: settings.direction ? settings.direction : "horizontal",
      slidesPerView: settings.slidesPerView ? settings.slidesPerView : "auto",
      spaceBetween: settings.spaceBetween ? settings.spaceBetween : 0,
      breakpoints: settings.breakpoints ? settings.breakpoints : {},
      autoplay: settings.autoplay
        ? {
            delay: settings.duration
              ? settings.duration
              : this.autoPlayDuration,
            disableOnInteraction: false,
          }
        : false,
    });
  },
});
