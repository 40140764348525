// add jquery
import $ from "jquery";

export default () => ({
  sliderEl: null,
  progressEl: null,
  total: 0,
  index: 0,
  indicator: 0,
  initialRender: true,
  animating: false,
  init() {
    this.update();
    this.next();
  
    //after initial render add transition classes to slides
    $(this.sliderEl)
      .find(".slide:not(.active)")
      .addClass("transition-all duration-1000");
    setTimeout(() => {
      $(this.sliderEl).find(".slide").addClass("transition-all duration-1000");
    }, 1000);

    this.initialRender = false;
  },
  update() {
    this.sliderEl = this.$refs.slider;
    this.total = this.sliderEl.children.length;
    this.progressEl = this.$refs.progress;
    let indicator_w = this.progressEl.getBoundingClientRect().width;
    this.indicator_step = indicator_w / this.total;
    this.indicator = this.indicator_step * this.index;
  },
  goToSlide(selectedSlide: number | null) {

    // at current slide do nothing
    if(selectedSlide === this.index) {
      return;
    }

    // if selected slide is less than current slide
    if(selectedSlide && selectedSlide > this.index) {
      // if the selectedSlide is not the next slide then we need to animate the slides before the selected slide
      if(selectedSlide !== this.index) {
        this.animateBeforeNext(selectedSlide);
        this.next(selectedSlide);
        return;
      }

      this.next();
    }
  },
  animateSlideBeforeNext(prevEl: any) {
      let next = prevEl;
      console.log(next);

      // if the prevEl before has holder set holder to the element before
      if($(prevEl).prev().hasClass('holder')) {
        var holder = $(prevEl).prev(".holder");
      }else{
        holder = $(next).clone(false, true);
      }
      
      $(next).before($(holder));
      // holder all class of test
      $(holder).css({ visibility: "hidden" });


      // make the next slide full width
      $(next).css({
        position: "fixed",
        left: $(holder).offset().left + "px",
        top: $(next).offset().top + "px",
        zIndex: "-1",
        // opacity: 0,
      });

      $(next).find(".content").css({
        opacity: 0,
      });
      
      // $(next).css({
      //   opacity: 0,
      // },
      // function () {

      // });


      $(next).animate(
        {
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
        },
        500,
        function () {
          window.setTimeout(function () {
            $(next).addClass("active");
            $(next).find(".content").animate(
              {
                opacity: 1,
              },
              800
            );
            $(next)
              .find(".content-details")
              .removeClass("hidden")
              .addClass("normal-case font-light leading-[21px]");

          }, 500);
        }
      );

      $(holder).animate(
        {
          width: 0,
        },
        250,
        function () {
          $(holder).addClass("holder");
        }
      );

  },
  animateBeforeNext(selectedSlide: number) {
    // array of slides before the index that was clicked
    // const prevElements = $(this.sliderEl).find(".slide:not(.active):not(.holder)").slice(0, selectedSlide - 2);

    // array of slides before the index that was clicked where data-index is less than the index that was clicked
    const prevElements = $(this.sliderEl).find(".slide:not(.active):not(.holder)").filter(function() {
      return $(this).data('index') < selectedSlide;
    });
    console.log(selectedSlide);
    console.log(prevElements);

    if(prevElements.length === 0) {
      return;
    }
  
    for (let index = 0; index < prevElements.length; index++) {
      let prevEl = prevElements[index];
      window.setTimeout(() => {
        this.animateSlideBeforeNext(prevEl);
      }, 250 * index);
      
    }
  },
  next(selectedSlide: number | null = null) {
    if (this.index >= this.total) {
      return;
    }
    this.nextSlide(selectedSlide);
    // if selectedSlide

    if(selectedSlide) {
      this.index = selectedSlide;
      return;
    }
    this.index++;
  },
  prev() {
    if (this.index <= 1) {
      return;
    }
    this.prevSlide();
    this.index--;
  },
  nextSlide(selectedSlide: number | null = null) {
    // Get Next Slide
    let nextSlidePosition = selectedSlide ? selectedSlide - 1 : this.index;
    let next = $(this.sliderEl).find(".slide:not(.holder)")[nextSlidePosition];

    // find content-details on slides that are
    let contactDetails = $(this.sliderEl).find(
      ".slide:not(.active) .content-details"
    );
    $(contactDetails).addClass("hidden");

    // Create hidden holder
    var holder = $(next).clone(false, true);
    $(next).before($(holder));
    $(holder).css({ visibility: "hidden" });

    // Animate Full Width
    $(next).css({
      position: "fixed",
      left: $(holder).offset().left + "px",
      top: $(next).offset().top + "px",
      zIndex: "-1",
      
    });

    $(next).find(".content").css({
      opacity: 0,
    });

    // hide content-details on inactive slides

    if (this.initialRender) {
      $(next).find(".content-details").removeClass("hidden");
      $(next)
        .find(".content-details")
        .addClass("normal-case font-light leading-[21px]");

      $(next).addClass("active");

      // set position to top left
      $(next).css({
        left: "0",
        top: "0",
        width: "100vw",
        height: "100vh",
      });

      // remove opacity
      $(next).find(".content").css({
        opacity: 1,
      });

      $(next)
        .find(".content-details")
        .removeClass("hidden")
        .addClass("normal-case font-light leading-[21px]");
    } else {
      const context = this;
      context.animating = true;
      $(next).animate(
        {
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          opacity: 1,
        },
        500,
        function () {
          window.setTimeout(function () {
            $(next).addClass("active");
            $(next).find(".content").animate(
              {
                opacity: 1,
              },
              800
            );
            $(next)
              .find(".content-details")
              .removeClass("hidden")
              .addClass("normal-case font-light leading-[21px]");

            context.animating = false;
          }, 500);
        }
      );
    }

    // Animate the Nav
    $(holder)
      .delay(250)
      .animate(
        {
          width: 0,
        },
        250,
        function () {
          $(holder).addClass("holder");
        }
      );
  },
  prevSlide() {
    const context = this;
    context.animating = true;

    // Wait for animation to finish

    let prev = $(this.sliderEl).find(".slide:not(.holder)")[this.index - 1];

    const sliderElement = this.sliderEl;

    var holder = $(prev).prev(".holder");
    // Animate the Nav
    $(holder).removeClass("holder");

    $(prev).find(".content-details").addClass("hidden");

    // Animate Slide back to holder
    $(prev)
      .css({
        position: "fixed",
        left: $(holder).offset().left + "px",
        top: $(holder).offset().top + "px",
        zIndex: "1",
        width: "",
        height: "",
        opacity: 1,
      })
      .removeClass("active");

    // After Animate Slide clear style remove holder
    window.setTimeout(function () {
      $(prev)
        .css({
          position: "",
          left: "",
          top: "",
        })
        .addClass("prev");

      context.animating = false;
      $(holder).remove();
    }, 1000);
  },
});
