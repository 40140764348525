import { AlpineComponent } from "alpinejs";

interface ServerResponse {
  errors?: {
    fromName?: string[];
    fromEmail?: string[];
    message?: {
      Body?: string[];
      Recaptcha?: string[];
    };
  };
  modelName: string;
  submission: {
    fromName: string;
    fromEmail: string;
    subject: string | null;
    message: {
      Recaptcha: string;
      Product: string;
      Body: string;
    };
    attachment: null;
  };
  message: string;
}

export default (): AlpineComponent => {
  return {
    submitting: false,
    serverResponse: null,
    selectMenuHasSelection: false,
    init() {},
    async submit() {
      this.submitting = true;

      const form = this.$refs.form as HTMLFormElement;

      if (form) {
        const googleSiteKey = import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY;

        window.grecaptcha.ready(async () => {

          const recaptchaResponseToken = await window.grecaptcha.execute(
            googleSiteKey,
            {
              action: "contact",
            }
          );

          const recaptchaInput = document.getElementById(
            "g-recaptcha-response"
          ) as HTMLInputElement | null;

          if (recaptchaInput) recaptchaInput.value = recaptchaResponseToken;

          // Prepare form data
          const formData = new FormData(form);

          try {
            // Send the request
            const response = await fetch("/", {
              method: "POST",
              headers: {
                Accept: "application/json",
              },
              body: formData,
            });
            const data = await response.json();

            this.serverResponse = data satisfies ServerResponse;

            // If the response has no errors then clear the form
            if (!this.serverResponse.errors) {
              form.reset();
              this.selectMenuHasSelection = false;
            }


            this.submitting = false;
          } catch (error: unknown) {
            // Handle error
            console.error(error);
            this.submitting = false;
          }
        });
      }
    },
  };
};
