interface Data {
  selectedId: number;
  productGroups: object;
}

export default (data: Data) => {
  return {
    currentItem: data.selectedId ?? 1,
    contentIds: [],
    transitioning: false,
    init() {
      const urlItem = window.location.hash.split("#")[1];

      // if there is a url item, find the name in the productGroups array
      if (urlItem) {
        const urlItemIndex = data.productGroups.findIndex(
          (item) => item.productLinkKey === urlItem
        );

        // get id of item
        const urlItemId = data.productGroups[urlItemIndex].id;

        setTimeout(() => {
          this.setCurrentIndex(urlItemId);
        }, 1000);

      }
    },
    setCurrentIndex(selectedIndex: number): void {

      console.log("set current index", selectedIndex);

      this.transitioning = true;

      setTimeout(() => {
        this.transitioning = false;
      }, 500);

      this.currentItem = selectedIndex;

      // Scroll to selected index
      const contentEl =
        window.innerWidth >= 768
          ? (this.$refs[`content${selectedIndex}`] as HTMLElement)
          : (this.$refs[`contentMobile${selectedIndex}`] as HTMLElement);

      if (contentEl) {
        // get height of the element

        setTimeout(() => {
          // scroll so element is at the top of the screen
          const contentElTop = contentEl.getBoundingClientRect();

          window.scrollTo({
            top: contentElTop.top + window.scrollY - 400,
            behavior: "smooth",
          });
        }, 600);
      }
    },
    isActiveInRow(rowIndex: number) {
      /**
       * Checks if any items on the same row are active
       * Uses the contentIds array to determine which items are on the same row
       */

      const activeItem = this.contentIds.find(
        (item) => item.id === this.currentItem
      );

      if (activeItem) {
        return activeItem.row === rowIndex;
      }

      return false;
    },
    setScrollListener(productId: number) {
      // Desktop elements
      const productContainer = this.$refs[
        `productsContainer${productId}`
      ] as HTMLElement;

      const scrollIndicator = this.$refs[
        `scrollIndicator${productId}`
      ] as HTMLElement;

      // Mobile elements
      const productContainerMobile = this.$refs[
        `productsContainerMobile${productId}`
      ] as HTMLElement;

      const scrollIndicatorMobile = this.$refs[
        `scrollIndicatorMobile${productId}`
      ] as HTMLElement;

      // Only show scrollIndicator if there is a scrollable container
      if (!this.isOverflown(productContainer)) {
        // desktop
        scrollIndicator?.classList.add("hidden");

        // mobile
        scrollIndicatorMobile?.classList.add("hidden");
      }

      if (productContainer || productContainerMobile) {
        // Desktop
        // set event listener on scroll
        productContainer.addEventListener("scroll", () => {
          if (productContainer.scrollTop > 20) {
            scrollIndicator?.classList.add("hidden");
          } else {
            scrollIndicator?.classList.remove("hidden");
          }
        });

        // Mobile
        // set event listener on scroll
        productContainerMobile.addEventListener("scroll", () => {
          if (productContainerMobile.scrollTop > 20) {
            scrollIndicatorMobile?.classList.add("hidden");
          } else {
            scrollIndicatorMobile?.classList.remove("hidden");
          }
        });
      }
    },
    closeCurrent(): void {
      this.currentItem = null;
    },
    isOverflown(element: HTMLElement): boolean {
      return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
      );
    },
  };
};
