import { debounce } from "../utils/helpers";

export default () => ({
  currentItem: null,
  contentIds: [],
  containerHeight: 0,
  init(): void {
    // Get all refs attached to this component;

    this.$nextTick(() => {
      // check width of screen is greater than 1024px
      if (window.innerWidth > 1024) {
        this.setContainerHeight();
      }

      // set current item to first item
      this.currentItem = 1;

      const setContainerHeight = this.setContainerHeight.bind(this);
      const contentContainer = this.$refs.contentContainer as HTMLElement;


      // Buffer to allow for window to be ready
      setTimeout(() => {
          const containerEl = contentContainer;
          if (containerEl) containerEl.style.height = "auto";
      }, 100);

      window.addEventListener(
        "resize",
        debounce(() => {
            const containerEl = contentContainer;
            if (containerEl) containerEl.style.height = "auto";
        })
      );
    });
  },
  setCurrentIndex(selectedIndex): void {
    this.currentItem = selectedIndex;
  },
  setContainerHeight(): void {
    // Get all content ids and set the tallest element to the container height
    this.contentIds.forEach((contentId) => {
      const contentHeight =
        this.$refs[`desktopContent${contentId}`].offsetHeight;
      if (contentHeight > this.containerHeight) {
        this.containerHeight = contentHeight;
      }
    });

    const containerEl = this.$refs.contentContainer as HTMLElement;

    if (containerEl) {
      containerEl.style.height = `${this.containerHeight}px`;
    }
  },
});
