import "../app.pcss";
import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
import Fade from "./directives/fade";
import Slider from "./components/slider";
import HeroSlider from "./components/HeroSlider";
import TwoColumnMenu from "./components/TwoColumnMenu";
import GridMenu from "./components/GridMenu";
import Form from "./components/Form";
import Dropdown from "./components/Dropdown";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

import "lazysizes";

// Adding alpine to window is useful for debugging
window.Alpine = Alpine;

// Disable and body scroll locks on mount
clearAllBodyScrollLocks();

// Initialise Component
Alpine.data("LogoSlider", Slider);
Alpine.data("HeroSlider", HeroSlider);
Alpine.data("TwoColumnMenu", TwoColumnMenu);
Alpine.data("GridMenu", GridMenu);
Alpine.data("Form", Form);
Alpine.data("Dropdown", Dropdown);
Alpine.data("MobileNav", () => ({
  open: false,
  toggleBodyScroll(target, open) {
    if (open) {
      disableBodyScroll(target);
    } else {
      enableBodyScroll(target);
    }
  },
}));

Alpine.plugin(Fade);
Alpine.plugin(collapse);

// Initialize Alpine
Alpine.start();


// Google maps

// Initialize and add the map
// let map;
// async function initMap(): Promise<void> {
//   // The location of Uluru
//   const position = { lat: -25.344, lng: 131.031 };

//   // Request needed libraries.
//   //@ts-ignore
//   const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
//   const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

//   // The map, centered at Uluru
//   map = new Map(
//     document.getElementById('map') as HTMLElement,
//     {
//       zoom: 4,
//       center: position,
//       mapId: 'DEMO_MAP_ID',
//     }
//   );

//   // The marker, positioned at Uluru
//   const marker = new AdvancedMarkerElement({
//     map: map,
//     position: position,
//     title: 'Uluru'
//   });
// }

// initMap()
