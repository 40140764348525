export default function (Alpine) {
  Alpine.directive("fade", (el, { modifiers }, { cleanup }: any) => {
    // get speed if it exists
    const speed =
      modifiers.find((modifier) => modifier.match(/^[0-9]+(ms|s)$/)) ?? "1s";

    const element = el as HTMLElement;

    // using opacity to animate a fade in
    element.style.opacity = "0";
    element.style.transition = `opacity ${speed} ease-in-out`;

    window.observedElementCount = window.observedElementCount ?? 0;

    if (!window.fadeObserver) {
      window.fadeObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const element = entry.target as HTMLElement;
            element.style.opacity = "1";
            window?.fadeObserver?.unobserve(element); // stop observing the image so it only happens once
            window.observedElementCount--;

            // // If the Intersection Observer is not observing any elements then destroy it
            if (window.observedElementCount === 0) {
              window?.fadeObserver?.disconnect();
            }
          }
        });
      });
    }

    window.fadeObserver.observe(element);
    window.observedElementCount++;

    cleanup(() => {
      // if still observing the element then stop observing it
      if (window.fadeObserver?.root === element) {
        window?.fadeObserver?.unobserve(element);

        // If the Intersection Observer is not observing any elements then destroy it
        if (window.observedElementCount === 0) {
          window?.fadeObserver?.disconnect();
        }
      }
    });
  });
}
