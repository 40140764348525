import { AlpineComponent } from "alpinejs";

export default (): AlpineComponent => {
  return {
    open: false,
    toggle() {
      if (this.open) {
        return this.close();
      }

      this.$refs.button.focus();

      this.open = true;
    },
    close(focusAfter: HTMLElement | null) {
      if (!this.open) return;

      this.open = false;

      focusAfter && focusAfter.focus();
    },
  };
};
